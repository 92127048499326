.toggle-box {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  > .chip {
    cursor: pointer
  }
  > .chip.selected {
    background-color: #007bff;  /* primary */
    color: white;
  }
}

.toggle {
  cursor: pointer;
  background-color: #dee2e6;  /* shade3 */
  width: 100px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: black;
}

.toggle.toggle-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.toggle.toggle-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

}

.toggle.selected {
  background-color: #007bff;  /* primary */
  color: white;
}
