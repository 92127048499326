/* Gray background */
.shade1 {
  background-color: #f8f9fa;
}

.shade2 {
  background-color: #e9ecef;
}

.shade3 {
  background-color: #dee2e6;
}

.blue1 {
  background-color: #007bff;
}

.blue2 {
  background-color: #0069d9;
}


.input-field.input-outlined {
  >input {
    border: 2px solid #eee;
    padding: 12px 16px 14px;
    width: calc(100% - 2.5rem);
    float: none;
    display: block;
    border-radius: 4px;
    transition: box-shadow, border-color 0.15s;

    &:focus:not([readonly]) {
      border-color: #26A69B;
      box-shadow: 0 1px 0 0px #26A69B;
    }
  }

  >label {
    left: 27px;
    display: inline-flex;
    width: auto !important;

    &.active {
      background: white;
      border-left: 4px solid white;
      border-right: 4px solid white;
      transform: translateY(-1.75rem);
      top: 1rem;
    }
  }
}


.input-field.input-simple {
  >input {
    /* border: 2px solid #eee; */
    border: none !important;

    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }

  >input::placeholder {
    color: #999;
  }

  >textarea {
    border: none !important;

    &:active {
      border: none !important;
    }

    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }

  /* font-weight: ; */
}

form {
  >.row {
    margin-bottom: 0;
  }

  >.row>.col {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0px;
  }
}

td {
  padding: 5px;
}
td div {
  &.small {
    font-size: 8px !important;
  }
  &.center {
    text-align: center;
  }
}

.strava-orange {
  background-color: rgb(233, 95, 42) !important;
}

.btn {
  text-transform: none !important;
}


/* Activity intensity */
tbody.activities {
  >tr {
    border-left: 2px solid white;
    &.zone_2 {
      border-left-color: #0069d9;
    }
    &.easy {
      border-left-color: #0069d9;
    }
    &.steady {
      border-left-color: #28a745;
    }
    &.tempo {
      border-left-color: #fd7e14;
    }
    &.intervals {
      border-left-color: #ffc107;
    }
    &.long {
      border-left-color: #6f42c1;
    }
    &.race {
      border-left-color: #dc3545;
    }
  }
}

/* case 'zone_2':
return '#0069d9'; // blue
case 'easy':
return '#0069d9'; // blue
case 'steady':
return '#28a745'; // green
case 'tempo':
return '#fd7e14'; // orange
case 'intervals':
return '#ffc107'; // yellow
case 'long':
return '#6f42c1'; // purple
case 'race':
return '#dc3545'; // red */

main {
  margin-bottom: 70px;
}
footer {
  position: fixed;
  bottom: -1px;
  width: 100%;
  height: 75px;
}

.foot-nav {
  height: 75px;
  > ul {
    list-style: none;
    height: 100%;
    > li {
      height: 100%;
      display: inline-block;
      width: calc(100% / 3);
      text-align: center;
      padding-top: 10px;
      text-align: center;
      > a {
        color: black;
      }
    }
  }  
}

